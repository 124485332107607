import React,
  { useState, useEffect }
from 'react';
import Link from 'next/link'
import Head from 'next/head'
import UserInfo from './common/UserInfo'
import UserHeaderButtons from './common/UserHeaderButtons'
import Footer from './common/Footer'
import StartButton from './common/StartButton'
import DelayRender from './post/DelayRender';
import NewsletterCard from './home/NewsletterCard';

export const Home = props => {
  const [cards, setCards] = useState();
  const [ncards, setNcards] = useState([]);
  const data = props.data

  useEffect(() => {
    if (!data || !data.posts) return;

    const c = [];
    for (const post of data.posts) {
      c.push(
        <>
          <hr className="my-4" />
          <Link href={post.url} className="flex flex-row px-3 py-6 rounded-xl hover:bg-gray-100">
            <div className="grow md:pr-4 ">
              <div className="flex flex-row ">
                <h3 className="text-xl font-medium grow break-all pr-2 md:pr-0">
                  {post.title}
                </h3>
                <div className="block md:hidden basis-1/4 flex-none">
                  {ResponsiveImages(post, false)}
                </div>
              </div>
              <div className="text-sm text-gray-500 mt-4 break-all leading-relaxed">
                {post.subtitle?.substring(0, 120)}{ post.subtitle?.length > 120 ? "..." : ""}
              </div>

              <div className="mt-4 w-full flex">
                <UserInfo
                  user={post.user}
                  publishedAt={post.publishedAt}
                />
                <div className="flex-grow" />
              </div>
            </div>
            <div className="hidden md:block flex-none basis-1/4">
              {ResponsiveImages(post, true)}
            </div>
          </Link>
        </>
      );
    }
    setCards(c);

    const nc = [];
    for (const newsletter of shuffleArray(data.newsletters.slice(0, 8))) {
      nc.push(
        <NewsletterCard
          key={newsletter.id}
          newsletter={newsletter}
        />
      );
    }
    setNcards(nc);
  }, [data]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const ResponsiveImages = (post, md) => {
    if (!post.thumbnail160) {
      return (
        <img
          src={post.thumbnail}
          alt="post"
          className={
            md
              ? "object-cover w-44 bg-white h-40 rounded-lg"
              : "object-cover bg-white h-20 w-full rounded-lg"
          }
        />
      );
    }

    return (
      <img
        id="srcSet"
        alt="post"
        className={
          md
            ? "object-cover w-44 bg-white h-40 rounded-lg"
            : "object-cover bg-white h-20 w-full rounded-lg"
        }
        srcSet={`${post.thumbnail160.url},
                ${post.thumbnail320.url} 2x,
                ${post.thumbnail480.url} 3x,
                ${post.thumbnail640.url} 4x`}
        src={post.thumbnail160.url}
      />
    );
  }

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    name: "みんなのニュースレター",
    description:
      "みんなのニュースレターではニュースレターの配信ができます。コンテンツの販売、コミュニティ運営が無料で開始できます。",
    url: "https://m-newsletter.com/",
    applicationCategory: "Newsletter Service",
    offers: {
      "@type": "Offer",
      price: "0",
      priceCurrency: "JPY",
    },
    featureList: ["ニュースレター配信", "コンテンツ販売", "コミュニティ運営"],
  };

  return (
    <>
      <Head>
        <title>みんなのニュースレター｜ニュースレターを無料で配信</title>
        <meta property="twitter:title" content="みんなのニュースレター" />
        <meta
          name="description"
          content="【無料で始められる】ニュースレターの作成・配信プラットフォーム。読者管理、コンテンツ販売、コミュニティ運営まで。初心者でも簡単にニュースレターをはじめられます。"
        />
        <meta
          property="twitter:description"
          content="【無料で始められる】ニュースレターの作成・配信プラットフォーム。読者管理、コンテンツ販売、コミュニティ運営まで。初心者でも簡単にニュースレターをはじめられます。"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content="【無料で始められる】ニュースレターの作成・配信プラットフォーム。読者管理、コンテンツ販売、コミュニティ運営まで。初心者でも簡単にニュースレターをはじめられます。"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          property="og:image"
          content="https://static-files.pages.dev/m-newsletter/images/ogp.png"
        />
        <meta
          property="twitter:image"
          content="https://static-files.pages.dev/m-newsletter/images/ogp.png"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
        <meta name="keywords" content="ニュースレター配信, メルマガ作成, ニュースレターツール, メールマガジン, メルマガ配信"/>
        <link rel="canonical" href="https://m-newsletter.com/" />
        <meta name="robots" content="index, follow" />
      </Head>
      <UserHeaderButtons />

      <div className="max-w-2xl mx-auto px-2 md:px-0 ">
        <div className="pt-16 md:pt-32 pb-4">
          <img
            src="https://static-files.pages.dev/m-newsletter/images/mail.svg"
            className="mx-auto w-16"
            alt="newsletter"
            width={64}
            height={64}
          />
          <img
            src="https://static-files.pages.dev/m-newsletter/images/title.png"
            className="mx-auto w-80"
            alt="みんなのニュースレター"
            width={320}
            height={31}
          />
        </div>
        <h1 className="w-fit mx-auto mt-2 mb-32 text-base font-normal underline underline-offset-4 decoration-8 decoration-gray-400">
          誰でもニュースレターが書ける場所、読める場所
        </h1>
      </div>

      <hr className="my-10" />

      <main className="max-w-2xl mx-auto px-2 md:px-0 ">
        <div className="mt-20">
          <div className="flex flex-row items-center w-fit mx-auto">
            <img
              src="/images/icons/bell.svg"
              className="w-10 mr-4"
              alt="one"
              width={80}
              height={80}
            />
            <h2 className="text-2xl my-0 font-semibold">
              今日のおすすめニュースレター記事
            </h2>
          </div>
          {cards}
          <hr className="my-10" />
          {cards && (
            <div className="text-center">
              ほかにもたくさんのニュースレターがあります 🎉
            </div>
          )}
        </div>

        {cards && (
          <div className="mx-auto w-fit my-20">
            <StartButton free={false} />
          </div>
        )}
      </main>

      <DelayRender>
        <div className="bg-gray-100">
          <div className="max-w-2xl mx-auto px-2 md:px-0 ">
            <div className="w-full  py-20 px-2 md:px-0">
              <div className="max-w-2xl mx-auto">
                <div className="flex flex-row items-center w-fit mx-auto">
                  <img
                    src="/images/icons/target.svg"
                    className="w-10"
                    alt="one"
                    width={80}
                    height={80}
                  />

                  <h2 className="text-2xl my-0 font-semibold">
                    「みんなのニュースレター」でできること
                  </h2>
                </div>
                <div className="mt-12 mb-4 flex items-center ">
                  <img
                    src="/images/icons/pen.svg"
                    className="w-20"
                    alt="pen"
                    width={80}
                    height={80}
                  />
                  <div className="text-xl font-bold ml-4">
                    ニュースレターを作る
                  </div>
                </div>
                ニュースレター記事を投稿して、メールで配信、ウェブサイトに公開できます
                <div className="mt-12 mb-4 flex items-center ">
                  <img
                    src="/images/icons/plant.svg"
                    className="w-20"
                    alt="pen"
                    width={80}
                    height={80}
                  />
                  <div className="text-xl font-bold ml-4">ファンを育てる</div>
                </div>
                メール購読でファンとのつながりを作ります
                <div className="mt-12 mb-4 flex items-center ">
                  <img
                    src="/images/icons/money.svg"
                    className="w-20"
                    alt="pen"
                    width={80}
                    height={80}
                  />
                  <div className="text-xl font-bold ml-4">収益を生み出す</div>
                </div>
                創作活動を支える収益を生み出します
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white pb-28">
          <div className="max-w-2xl mx-auto px-2 md:px-0 ">
            <div className="flex flex-row items-center w-fit mx-auto mt-28 mb-20 ">
              <img
                src="/images/icons/chat-square-quote.svg"
                className="w-10 mr-4"
                alt="one"
                width={80}
                height={80}
              />
              <h2 className="text-2xl my-0 font-semibold">
                人気のニュースレター
              </h2>
            </div>
            {ncards}
          </div>
        </div>

        <div className="bg-gray-100">
          <div className="max-w-2xl mx-auto px-2 md:px-0 ">
            <div className="w-full  py-12 px-2 md:px-0">
              <div className="flex flex-row items-center w-fit mx-auto mt-28 mb-20 ">
                <img
                  src="/images/icons/chat-square-quote.svg"
                  className="w-10 mr-4"
                  alt="one"
                  width={80}
                  height={80}
                />
                <h2 className=" text-2xl font-semibold my-0">
                  作ったニュースレターもその成果も
                  <br />
                  すべてはクリエイターのもの
                </h2>
              </div>
              <div className="p-6 rounded-xl bg-gray-100 mt-12 md:mt-20">
                <div className="mb-4 flex items-center ">
                  <img
                    src="/images/icons/one.svg"
                    className="w-20"
                    alt="one"
                    width={80}
                    height={80}
                  />
                  <div className="ml-4 text-2xl font-bold">
                    購読者アドレスはクリエイターのもの
                  </div>
                </div>
                獲得した購読者のメールアドレスは全てクリエイターのもの。メールリストは大切な顧客リスト。エクスポートして手元に置けます。
              </div>
              <div className="p-6 rounded-xl bg-gray-100 mt-20">
                <div className="mb-4 flex items-center ">
                  <img
                    src="/images/icons/two.svg"
                    className="w-20"
                    alt="two"
                    width={80}
                    height={80}
                  />
                  <div className="ml-4 text-2xl font-bold">
                    ニュースレターはクリエイターのもの
                  </div>
                </div>
                投稿したニュースレター記事は全てエクスポートできます。いつでも移行できます。
              </div>
              <div className="p-6 rounded-xl bg-gray-100 mt-20">
                <div className="mb-4 flex items-center ">
                  <img
                    src="/images/icons/three.svg"
                    className="w-20"
                    alt="three"
                    width={80}
                    height={80}
                  />
                  <div className="ml-4 text-2xl font-bold">
                    手数料は収益が入った時だけ
                  </div>
                </div>
                手数料（13.6％）が発生するのはクリエイターに収益が入った時だけ。収益が無ければ永年ゼロ円。
              </div>
            </div>
          </div>
        </div>
      </DelayRender>

      <DelayRender>
        <div className="mx-auto w-fit mt-20">
          <StartButton free={true} />
        </div>
      </DelayRender>

      <DelayRender>
        <Footer />
      </DelayRender>
    </>
  );
}

export default Home;
